import axios from "axios";

export const tourLocationService = {
    async getLocations(params) {
        return (await axios.get('/tour-locations/', { params })).data
    },
    async postLocation(data) {
        return (await axios.post('/tour-locations/', data)).data
    },
    async deleteLocation(id) {
        return (await axios.delete(`/tour-locations/${id}/`)).data
    },
    async patchLocation(data) {
        return (await axios.patch(`/tour-locations/${data.id}/`, data)).data
    },
}

